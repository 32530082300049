import React, {
  useRef,
  useState
} from 'react';

// ...

export function Audio({ src, repeatCount = -1 }) {
  const audioRef = useRef(null);
  const [count, setCount] = useState(1);

  const repeat = () => {
    const audio = audioRef.current;
    if (!audio) {
      return;
    }

    if (count < repeatCount || repeatCount === -1) {
      audio.play();
      setCount(count + 1);
    } else {
      audio.pause();
    }
  }

  return (
    <audio  ref={audioRef} autoPlay onEnded={repeat}>
      <source src={src} type="audio/mpeg" />
    </audio>
  )
}