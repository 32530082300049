import React, {
    useState
  } from 'react';
  
  import {
    useNavigate
  } from 'react-router-dom';
  
  import {
    Button,
    CircularProgress,
    Grid,
    TextField,
    Typography as T
  } from '@material-ui/core';
  
  import {
    makeStyles
  } from '@material-ui/core/styles';
  
  import {
    Formik,
    Form,
    Field,
    ErrorMessage
  } from 'formik';
  
  import * as Yup from 'yup';
  
  import {
    useAuth,
    useNotify
  } from 'hooks';
  
  import {
    baseStyles
  } from '../../baseStyles';

  import {
    verifySMS
  } from 'services/firebase';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
  
  // ...
  
  const useStyles = makeStyles(theme => ({
    button: {
      ...baseStyles.button,
  
      backgroundColor: theme.button.primary
    },
  
    container: {
      paddingLeft: '15%',
      paddingRight: '15%',
      marginTop: '10%',
      marginBottom: '10%'  
    },
  
    header: {
      textAlign: 'center',
      paddingBottom: 25
    },

    subheader: {
        textAlign: 'left',
        paddingBottom: 25
      },
  
    field: {
      paddingBottom: 15
    }
  }));
  
  // ...

  // Form settings.
  const initialValues = {
    code: ''
  };
  
  const validationSchema = Yup.object().shape({
    code:
      Yup
        .string()
        .required('Required')
  });
  
  // ...
  
  const errors = {
    'auth/invalid-verification-code': 'The code you used is invalid. You can try again by logging-in to get a new code.',
    'auth/code-expired': 'The code you used has expired. You must use the code within 10 minutes of sending.'
  };
  
  // ...

  export function PhoneFactorForm({actions, mfa}) {
    const styles = useStyles();
  
    const navigate = useNavigate();
    const [attempts, setAttempts] = useState(0);
    const[isSubmitting, setIsSubmitting] = useState(false);
  
    const {
        profile,
        signOut,
        setMFAConfirmed
    } = useAuth();
  
    const {
      notifyError,
      notifyWarning
    } = useNotify();

    const {
      phoneNumber
  } = mfa;
  
    const censoredPhoneNumber = phoneNumber?.replace(/[1234567890-]{7}/, "*******") ?? "(Error fetching phone number)";
  
    // ...
 

    const handleSubmit = async ({ code }) => {  
      setIsSubmitting(true); 
        try{
          const verified = await verifySMS(profile.id, code);
          if (verified.approved === false) { // code is incorrect ?? other
            const prevAttempts = attempts
            setAttempts(prevAttempts + 1)
            if (attempts >= 1){
              let invalid = new Error();
              invalid.code = 'auth/invalid-verification-code';
              throw invalid;
            }
            notifyWarning('The code you entered is invalid. You have 1 attempt remaining.')
            setIsSubmitting(false);
          }
          if (verified.approved === true) {
            // persistence was set to none: if they are logged in once they navigate away they will be logged out
            // make it so that when they navigate they wont get logged out anymore
            firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
            setMFAConfirmed(true)
            navigate("/");
          }
        } catch (error) {
          await signOut();
          notifyError(errors[error.code] ?? 'Unknown authentication error');
          navigate("/");
        }
    };
  
    // since we're now signing in the user to get their phone num,
    // when they click on 'cancel' we need to sign them out:
    const handleMFACancel = async () => {
      await signOut();
      handleCancel();
      navigate('/');
    };
    
    const {
      handleCancel
    } = actions;
  
    // ...
  
    return (
        <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
  
        onSubmit={handleSubmit}
  
        validateOnChange
      >
        {({ values, dirty, isValid, isSubmitting }) => (
          <Grid
            className={styles.container}
            container
            direction="column"
          >
            <Grid item>
              <T className={styles.header} variant="h5">
                Enter your code
              </T>
              <T className={styles.subheader} variant="body1">
                  A code has been sent to your phone with the number {censoredPhoneNumber}. Enter it below.
              </T>
            </Grid>
            <Grid item>
              <Form
                noValidate
                autoComplete="off"
              >
                <Field
                  name="code"
                  placeholder="Verification Code"
                  autoFocus
                  as={TextField}
                  className={styles.field}
                  variant="outlined"
                  fullWidth
                  helperText={<ErrorMessage name="code" />}
                />
  
                <Grid container justifyContent="space-between">
                  <Button
                    className={styles.buttonCancel}
                    onClick={handleMFACancel}
                  >
                    Cancel
                  </Button>
  
                  <Button
                    className={styles.button}
                    type="submit"
                    disabled={!(dirty && isValid) || isSubmitting}
                    disableElevation
                    style={{position: 'relative'}}
                  >
                    <span style={{visibility: isSubmitting ? 'hidden' : 'visible'}}>
                    SUBMIT
                    </span>
                    {isSubmitting && (<CircularProgress size={24}
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: -12,
                      marginLeft: -12,
                    }}
                    />
                    )}
                  </Button>
                </Grid>
              </Form>
            </Grid>
          </Grid>
        )}
      </Formik>
    )
  }