import {
  useContext
} from 'react';

import {
  AuthenticationContext
} from '../providers';

// ...

export function useAuth() {
  return useContext(AuthenticationContext);
}