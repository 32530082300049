import AgoraRtmSdk from 'agora-rtm-sdk';

import {
  v4 as uuidv4
} from 'uuid';

// ...

const MAX_AGORA_SIZE = 32 * 1024;

const MAX_CHUNK_SIZE = 24 * 1024;

// ...

let rtmClient = AgoraRtmSdk.createInstance(
  process.env.REACT_APP_AGORA_APP_ID,
  { logFilter : AgoraRtmSdk.LOG_FILTER_WARNING });

let uid;
let channel;

// ...
// Public.
function login(token, _uid) {
  uid = _uid;
  rtmClient.login({ token, uid });
}

function logout() {
  rtmClient.logout();
  uid = null;
}

function getChannel(channelId) {
  channel = rtmClient.createChannel(channelId);

  return channel;
}

function join() {
  return channel.join();
}

function leave() {
  if (channel) {
    channel.leave();
    channel = null;
  }
}

function sendMessage(message, peerId) {
  const messageString = JSON.stringify(message);

  if (messageString.length < MAX_AGORA_SIZE) {
    return sendMessageInternal(messageString, peerId);
  }

  // ...
  // Chunk message as its length exceeds the maximum (Agora) message size.
  let noChunks = Math.floor(messageString.length / MAX_CHUNK_SIZE);
  let remainder = messageString.length % MAX_CHUNK_SIZE;

  let index = 0;
  let chunkId = 1;

  const messageId = uuidv4();

  while (noChunks !== 0) {
    const content = messageString.substring(index, index + MAX_CHUNK_SIZE);

    let lastChunk = true;
    if (remainder) {
      lastChunk = false;
    } else {
      lastChunk = noChunks === 1 ? true : false
    }

    const chunkObject = {
      chunked: true,
      messageId,
      chunkId,
      lastChunk,
      content
    };

    sendMessageInternal(JSON.stringify(chunkObject), peerId);

    index += MAX_CHUNK_SIZE;

    --noChunks;

    ++chunkId;
  }

  if (remainder) {
    const content = messageString.substring(index);

    const chunkObject = {
      chunked: true,
      messageId,
      chunkId,
      lastChunk: true,
      content
    };

    sendMessageInternal(JSON.stringify(chunkObject), peerId);
  }
}

function on(eventName, listener) {
  rtmClient.on(eventName, listener);
}

function getUser() {
  return uid;
}

// ...
// Internal.
function sendMessageInternal(text, peerId) {
  rtmClient.sendMessageToPeer(
    {
      text
    },
    peerId
  );
}

// ...

const rtm = {
  login,
  logout,

  getChannel,

  join,
  leave,

  sendMessage,

  on,
  getUser
};

export default rtm;