import {
  createMachine,
  assign,
  sendTo
} from 'xstate';

import {
  callMachine
} from './call';

// ...

function getInitialContext() {
  return {
    channel: {
      id: '',
      host: ''
    },
    members: [],
    memberColors: {}
  }
}

// ...
// Actions.
const setChannel = assign({
  channel: (_context, { data, peerId: host }) => ({
    id: data.channelId,
    host
  })
});

const resetContext = assign((_context, _event) => getInitialContext());

const rejectCall = sendTo(
  'agoraService',
  context => ({
    type: 'AGORA_REJECT',
    channel: context.channel
  })
);

const join = sendTo(
  'agoraService',
  context => ({
    type: 'AGORA_JOIN',
    channel: context.channel
  })
);

const leave = sendTo(
  'agoraService',
  {
    type: 'AGORA_LEAVE'
  }
);

const memberJoined = assign({
  members: (context, { profile }) => {
    return [
      ...context.members,
      profile
    ]
  }
});

const memberLeft = assign({
  members: (context, { uid }) => context.members.filter(member => member.id !== uid)
});

const memberColors = assign({
  memberColors: (_context, event) => {
    return event.data.participantColors.reduce(
      (acc, participant) => {
        acc[participant.userId] = participant.color;
        return acc;
      },
      {}
    );
  }
});

const frameData = sendTo(
  'callMachine',
  (_context, event) => event
);

const proxyEvent = sendTo(
  'agoraService',
  (context, { event }) => ({
    type: 'PROXY_EVENT',

    event,
    
    channel: context.channel
  })
);

// ...
// Guards.
const shouldLeaveCall = (context, event) => {
  // if there is no (active) channel, transition immediately. 
  if (!context.channel.id) {
    return true;
  }

  return context.channel.host === event.peerId && context.channel.id === event.data.channelId; 
};

// ...

export const agoraMachine = createMachine(
  {
    id: 'agora',
    initial: 'disconnected',
    context: getInitialContext(),
    invoke: {
      id: 'agoraService',
      src: 'agoraService'
    },
    states: {
      disconnected: {
        on: {
          LOGIN_REQUEST: 'connecting'
        }
      },
      connecting: {
        on: {
          LOGIN_SUCCESS: 'connected'
        }
      },
      connected: {
        initial: 'idle',
        on: {
          LEAVE_CALL: {
            target: '#agora.connected.idle',
            cond: {
              type: 'shouldLeaveCall'
            },
            actions: 'leave'
          }
        },
        states: {
          idle: {
            entry: ['idleStateEntry','resetContext'],
            on: {
              JOIN_CALL: {
                target: 'pending',
                actions: 'setChannel'
              }
            }
          },
          pending: {
            on: {
              JOIN_REFUSE: {
                target: 'reject',
                actions: 'rejectCall'
              },
              JOIN_ACCEPT: 'call'
            }
          },
          reject: {
            on: {
              '': { target: 'idle' }
            }
          },
          call: {
            entry: ['callStateEntry'],
            initial: 'joining',
            on: {
              // ...
              // Channel messages.
              MEMBER_JOINED: {
                actions: 'memberJoined'
              },
              
              MEMBER_LEFT: {
                actions: 'memberLeft'
              },
              
              PARTICIPANT_COLORS: {
                actions: 'memberColors'
              },
              
              // ...

              LEAVE: {
                target: '#agora.connected.idle',
                actions: 'leave'
              },
            },
            states: {
              joining: {
                entry: 'join',
                on: {
                  JOIN_SUCCESS: 'joined'
                }
              },
              joined: {
                invoke: {
                  id: 'callMachine',
                  src: 'callMachine'
                },
                on: {
                  PROXY_EVENT: {
                    actions: 'proxyEvent'
                  },

                  FRAME_DATA: {
                    actions: 'frameData'
                  }
                }
              }
            }
          }
        }
      }
    }
  },
  {
    actions: {
      getInitialContext,

      setChannel,
      resetContext,

      rejectCall,
      join,
      leave,

      memberJoined,
      memberLeft,

      memberColors,

      frameData,
      proxyEvent
    },
    services: {
      callMachine
    },
    guards: {
      shouldLeaveCall
    }
  }
);