export const baseStyles = {
  container: {
    height: '100%',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  canvas: {
    zIndex: 3,
    position: 'absolute',
  }
};