import React from 'react';

import {
  makeStyles
} from '@material-ui/core/styles';

// ...

const useStyles = makeStyles({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 999
  }
});

// ...

export function Overlay({ style, children }) {
  const styles = useStyles();

  // ...

  return (
    <div className={styles.overlay} style={style}>
      {children}
    </div>
  )
}