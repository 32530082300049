import agoraRtm from './rtm';
import agoraRtc from './rtc';

// ...

let agoraService;

export function getAgoraService() {
  if (!agoraService) {
    agoraService = {
      rtm: agoraRtm,
      rtc: agoraRtc
    };
  }

  return agoraService;
}