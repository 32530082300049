import firebase from 'firebase/compat/app';

import {
  v4 as uuidv4
} from 'uuid';

import {
  firebaseConfig,
  vapidKey,
  getCloudFunctionUrl
} from 'firebaseConfig';

import axios from 'axios';

import sha256 from 'js-sha256';

const md5 = require('blueimp-md5');

// ...
// Storage.

function getDownloadUrl(storageUri) {
  return firebase.storage().refFromURL(storageUri).getDownloadURL();
}

async function uploadDataUrl(dataUrl) {
  const filePath = `images/${uuidv4()}.jpg`;
  const imageRef = firebase.storage().ref().child(filePath);

  await imageRef.putString(
    dataUrl,
    'data_url',
    {
      contentType: 'image/jpg'
    }
  );

  return `gs://${firebaseConfig.storageBucket}/${filePath}`;
}

// ...
// User.
export async function fetchProfile(id) {
  const profileDoc = await firebase.firestore()
    .collection('profiles')
    .doc(id)
    .get();

  const profile = profileDoc.data();

  if (!profile) {
    return null;
  }

  // ...

  let image = null;
  if (profile.image) {
    image = await getDownloadUrl(profile.image);
  }

  return {
    ...profile,

    id,
    image
  }
}

export async function createProfile(uid, profile) {
  const nullable = value => value || null;

  const profileDoc = await firebase.firestore()
    .collection('profiles')
    .doc(uid);

  const {
    first,
    last,
    phoneNumber,
    titleRef,
    departmentRef,
    primaryRoleRef,
    secondaryRoleRef,
    description,
    image,
    AR
  } = profile;

  // ...
  // Upload profile image.
  let dataUrl = null;

  if (image) {
    dataUrl = await uploadDataUrl(image);
  }

  // ...
  // Generate Agora RTC uid.
  //
  // The generated uid is based on the uid used for logging onto Agora RTM.
  const rtcUid = parseInt(md5(uid, process.env.REACT_APP_AGORA_APP_ID).substring(0, 8), 16);

  await profileDoc.set({
    first,
    last,
    phoneNumber,
    titleRef:
      nullable(titleRef),
    departmentRef:
      nullable(departmentRef),
    primaryRoleRef:
      nullable(primaryRoleRef),
    secondaryRoleRef:
      nullable(secondaryRoleRef),
    description,
    image:
      dataUrl,
    AR,
    rtcUid
  });

  // ...
  
  await firebase.firestore()
    .collection('users')
    .doc(uid)
    .set({
      pincode: sha256(profile.pin)
    });
}

// ...

export async function updateProfile(uid, updates) {

  // Upload profile image.

  let image = updates.image;
  
  if (image && image.startsWith('data:')) {
    image = await uploadDataUrl(image);
  }

  // ...

  const {
    first,
    last,
    phoneNumber,
    titleRef,
    departmentRef,
    primaryRoleRef,
    secondaryRoleRef,
    description
  } = updates;

  await updateDocument('profiles', uid, {
    first,
    last,
    phoneNumber,
    titleRef,
    departmentRef,
    primaryRoleRef,
    secondaryRoleRef,
    description,
    image
  });

  // ...
  await firebase.firestore()
    .collection('users')
    .doc(uid)
    .set({
      pincode: sha256(updates.pin)
    });
}

// ...
// Messaging.
function getTokenUpdated() {
  return window.localStorage.getItem('tokenUpdated') === '1';
}

function setTokenUpdated(updated) {
  window.localStorage.setItem('tokenUpdated', updated ? '1' : '0');
}

export async function getPushToken(uid) {
  if (!firebase.messaging.isSupported()) {
    return;
  }

  const fcmToken = await firebase.messaging().getToken({
    vapidKey
  });

  if (!getTokenUpdated()) {
    await updateDocument('profiles', uid, {
      fcmToken
    });

    setTokenUpdated(true);
  }
}

export async function deleteToken(uid) {
  if (firebase.messaging.isSupported()) {
    await firebase.messaging().deleteToken();
  }

  setTokenUpdated(false);

  return deleteField('profiles', uid, 'fcmToken');
}

// ...
// Auth.
export async function isWhitelisted(email) {
  const result = await axios({
    url: getCloudFunctionUrl('isWhitelisted'),
    method: 'POST',
    headers: {
      Authorization: process.env.REACT_APP_TOKEN_SECRET
    },
    data: {
      email
    }
  });

  return result.data;
}

export async function sendSMS(requestingUser){ 
  const result = await axios({
    url: getCloudFunctionUrl('sendSMS'),
    method: 'POST',
    headers: {
      Authorization: process.env.REACT_APP_TOKEN_SECRET
    },
    data: {
      requestingUser
    }
  });
  
  return result.data;
}

export async function verifySMS(requestingUser, enteredCode){
  const result = await axios({
    url: getCloudFunctionUrl('verifySMS'),
    method: 'POST',
    headers: {
      Authorization: process.env.REACT_APP_TOKEN_SECRET
    },
    data: {
      requestingUser,
      enteredCode
    }
  });
  
  return result.data;
}

// ...
// Generic.
export async function fetchCollection(name) {
  const collection = await firebase.firestore()
    .collection(name)
    .get();

  return collection.docs.map(doc => ({
    ...doc.data(),

    id: doc.id
  }));
}

function updateDocument(collection, id, updates) {
  return firebase.firestore()
    .collection(collection)
    .doc(id)
    .update(updates);
}

function deleteField(collection, id, field) {
  return firebase.firestore()
    .collection(collection)
    .doc(id)
    .update({
      [field]: firebase.firestore.FieldValue.delete()
    });
}