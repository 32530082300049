import React, {
  useRef
} from 'react';

import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@material-ui/core';

import {
  RotateLeft,
  RotateRight
} from '@material-ui/icons';

import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

// ...

export function ProfileImage({ image, onClose, onSelect }) {
  const cropRef = useRef();

  // ...

  const handleSelect = () => {
    onSelect(cropRef.current.cropper.getCroppedCanvas().toDataURL());
  };

  const handleRotateLeft = () => {
    cropRef.current.cropper.rotate(-90);
  }

  const handleRotateRight = () => {
    cropRef.current.cropper.rotate(90);
  }

  // ...

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open
      onClose={onClose}
    >
      <DialogContent>
        <Cropper
          ref={cropRef}
          style={{ height: 512, width: "100%" }}
          src={image}
          background={false}
          initialAspectRatio={1}
          minCropBoxHeight={256}
          minCropBoxWidth={256}
          dragMode="none"
          cropBoxResizable={false}
          zoomable={true}
          toggleDragModeOnDblclick={false}
        />
        <DialogContentText>Hint: You can scroll with your mouse or pinch to adjust image size.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={onClose}
        >
          Cancel
          </Button>
        <Button 
          color="primary"
          onClick={handleSelect}
        >
          Select
        </Button>
        <IconButton 
          color="primary"
          onClick={handleRotateLeft}
        >
          <RotateLeft />
        </IconButton>
        <IconButton 
          color="primary"
          onClick={handleRotateRight}
          
        >
          <RotateRight />
        </IconButton>
      </DialogActions>
    </Dialog>
  )
}