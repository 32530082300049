import {
  useState,
  useEffect
} from 'react';

import {
  useNotify
} from 'hooks';

import {
  getAgoraService
} from 'services/agora';

// ...

const errors = {
  'NOT_SUPPORTED': 'The browser you are using does not support options required for Vision Beyond to work.',
  'DEVICE_NOT_FOUND': 'Vision Beyond could not find a connected microphone.',
  'PERMISSION_DENIED': 'Vision Beyond could not get permission to access your microphone.'
};

export function useAudio() {
  const {
    longNotifyError
  } = useNotify();

  const [mute, setMute] = useState(false);

  useEffect( () => {
    (async function execute() {
      const {
        rtc
      } = getAgoraService();

      // ...
      // Publish local audio.
      try{
        await rtc.playAudio();
      } catch (e) {
        longNotifyError(errors[e.code] ?? 'Unknown error with audio stream.');
        console.log(e);
        
      }
      // ...
      // Subscribe to remote audio tracks.
      rtc.onUserPublished((uid, mediaType) => {
        if (mediaType === 'audio') {
          rtc.playRemoteAudio(uid);
        }
      });
    }());
  }, []);

  // ...

  return {
    mute,
    setMute(shouldMute) {
      getAgoraService().rtc.setMute(shouldMute);
      setMute(shouldMute);
    }
  }
}
