import React from "react";

import { 
  Routes, 
  Route 
} from "react-router-dom";

import {
  createBrowserHistory
} from 'history';

import {
  RequireAuth
} from './components';

import {
  Auth,
  Error,
  Main,
  Profile
} from './pages';

// ...

export default function AppRoutes() {
  let history = createBrowserHistory();

  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth redirectTo="/auth">
            <Main  history={history}/>
          </RequireAuth>
          }
      />
      <Route
        path="profile"
        element={
          <RequireAuth redirectTo="/auth">
            <Profile />
          </RequireAuth>
          }
      />
      <Route
        path="auth"
        element={<Auth />}
      />
      <Route
        path="error"
        element={<Error />}
      />
      <Route
        path="*"
        element={<div>Page not found</div>}
      />
    </Routes>
  );
}
