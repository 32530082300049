import {
  useState,
  useEffect,
  useCallback
} from 'react';

import {
  getAgoraService
} from 'services/agora';

// ...

export function useVideo(mode, onVideoSubscribed, onFrameGrab) {
  const [subscribers, setSubscribers] = useState([]);

  const onVideoSubscribedMemoized = useCallback(onVideoSubscribed, []); // eslint-disable-line react-hooks/exhaustive-deps
  const onFrameGrabMemoized       = useCallback(onFrameGrab, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getAgoraService().rtc.onUserPublished((uid, mediaType) => {
      if (mediaType === 'video') {
        // note: Only the host is able to stream video, thus subscribers.length <= 1.
        setSubscribers(prevSubscribers => [
          ...prevSubscribers,
          uid
        ]);

        onVideoSubscribedMemoized();
      }
    });
  }, [onVideoSubscribedMemoized]);

  useEffect(() => {
    const {
      rtc
    } = getAgoraService();

    switch (mode) {
      case 'video-play':
        subscribers.forEach(subscriber => {
          if (rtc.hasRemoteVideo(subscriber)) {
            rtc.playRemoteVideo(subscriber, 'video-container');
          }
        });

        break;

      case 'video-stop':
        const canvas = document.createElement('canvas');

        for (const i in subscribers) {
          const uid = subscribers[i];

          if (rtc.hasRemoteVideo(uid)) {
            const video = document.getElementById('video-container').querySelector('video');

            // ...
            // Scale video capture to viewport dimensions, maintaining aspect ratio.
            const ratio = video.videoWidth / video.videoHeight;

            let canvasW = window.innerWidth;
            let canvasH = canvasW / ratio;

            if (canvasH > window.innerHeight) {
              canvasH = Math.floor(window.innerHeight);
              canvasW = Math.floor(canvasH * ratio);
            }

            canvas.width = canvasW;
            canvas.height = canvasH;

            // ...

            const canvasContext = canvas.getContext('2d');
            canvasContext.drawImage(video, 0, 0, canvasW, canvasH);

            rtc.stopRemoteVideo(uid);

            onFrameGrabMemoized({
              dataUrl:
                canvas.toDataURL(),
              width:
                canvasW,
              height:
                canvasH
            });

            break;
          }
        }

        break;

      default:
        // ...
    }
  }, [mode, subscribers, onFrameGrabMemoized]);
}
