import React, {
  useReducer,
  useEffect,
  useRef
} from 'react';

import firebase from 'firebase/compat/app';

import {
  fetchProfile
} from 'services/firebase';

// ...

function initState() {
  return {
    user: firebase.auth().currentUser,
    profile: null
  };
}

function authReducer(state, action) {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        user: action.user
      };

    case 'SET_PROFILE':
      return {
        ...state,
        profile: action.profile
      };

      case 'SET_MFA':
        return {
          ...state,
          mfaConfirmed: action.mfaConfirmed
        };

    default:
      return initState();
  }
}

export const passwordMinLength = process.env.REACT_APP_PASSWORD_MIN_LENGTH || 8;
export const passwordRegex = new RegExp(
  `^(?:(?=.*\\d)(?=.*[^A-Za-z0-9])(?=.*[a-z])(?=.*[A-Z]))(?!.*(.).\\1{2,})[A-Za-z0-9!~<>,;:_=?*+#."&§%°()\\|\\[\\]\\-\\$\\^\\@\\/]{${passwordMinLength},128}$` // NOSONAR
);

// ...

export const AuthenticationContext = React.createContext();

export function AuthenticationProvider({ children }) {
  const [authState, dispatch] = useReducer(
    authReducer,
    initState()
  );

  const initializedRef = useRef(false);

  // ...

  useEffect(() => {
    return firebase.auth().onAuthStateChanged(async (_user) => {
      initializedRef.current = true;

      dispatch({
        type: 'SET_USER',

        user: _user
      });

      if (_user === null) {
        dispatch({
          type: 'SET_MFA',

          mfaConfirmed: false
        });
      }

      // ...

      dispatch({
        type: 'SET_PROFILE',

        profile: _user ? await fetchProfile(_user.uid) : null
      });
    });
  }, []);

  // ...

  const {
    user,
    profile,
    mfaConfirmed
  } = authState;

  // ...

  const signIn = (email, password) => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }

  const sendEmailVerification = async(passedUser) => {
    if(!passedUser){
      user.sendEmailVerification();
    } else {
      passedUser.sendEmailVerification();
    }
  }

  const initRecaptcha = (id, settings) => {
    return new firebase.auth.RecaptchaVerifier(id, settings);
  }

  const signOut = async (onSignOut) => {
    if (onSignOut) {
      await onSignOut();
    }

    // MFA not confirmed when user is signed out
    setMFAConfirmed(false)

    await firebase.auth().signOut();
  };

  const signup = async (email, password) => {

    const data = await firebase.auth().createUserWithEmailAndPassword(email, password);

    await data.user.sendEmailVerification().then(() => {
    	console.log("Email verification sent")
    }).catch((error) => {
    	console.log("Error sending email verification")
      throw error
    });

    return data.user.uid;
  }

  const verifyEmail = async (code) => {
    firebase.auth().applyActionCode(code);
  }

  const userExists = async (email) => {
    const result = await firebase
      .auth()
      .fetchSignInMethodsForEmail(email);

    return result.length > 0;
  }

  const sendPasswordResetEmail = (email) => {
    return firebase.auth().sendPasswordResetEmail(email);
  };

  const confirmPasswordReset = (code, newPassword) => {
    return firebase.auth().confirmPasswordReset(code, newPassword);
  }

  const refreshProfile = async (uid) => {
    const _profile = await fetchProfile(uid);
    dispatch({
      type: 'SET_PROFILE',

      profile: _profile
    });
    
    return _profile;
  }

  const setMFAConfirmed = (confirmed) => {
    dispatch({
      type: 'SET_MFA',

      mfaConfirmed: confirmed
    });
  }

  // ...

  const value = {
    user,
    profile,
    mfaConfirmed,

    signIn,
    signOut,
    signup,
    sendEmailVerification,
    verifyEmail,

    userExists,
    sendPasswordResetEmail,
    refreshProfile,
    confirmPasswordReset,

    setMFAConfirmed,
    initRecaptcha
  };

  return initializedRef.current
    ? <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>
    : null;
}