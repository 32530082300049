import React from 'react';

import {
  useLocation 
} from 'react-router-dom';

import {
  Typography as T
} from '@material-ui/core';

// ...

export function Error({ error }) {
  const location = useLocation();

  return (
    <T variant="body1">
      An application error occurred: {location.state?.error?.message ?? 'Unknown error'}
    </T>
  )
}