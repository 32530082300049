import React from 'react';

import {
  Typography as T
} from '@material-ui/core';

import {
  makeStyles
} from '@material-ui/core/styles';

import {
  useAuth
} from 'hooks';

import {
  baseStyles
} from '../../baseStyles';

import {
  getLayout
} from '../components/DefaultLayout';

import {
  InactivityDialog
 } from 'components'

// ...

const {
  center
} = baseStyles;

const useStyles = makeStyles({
  center: {
    ...center,
    textAlign: 'center',

    '& > *:first-child': {
      marginBottom: 15
    }
  }
});

// ...

function Idle() {
  const styles = useStyles();

  const {
    profile
  } = useAuth();

  if (!profile) {
    return null;
  }
  return (
    <div className={styles.center}>
      <T variant="h4">Welcome {profile.first}!</T>
      <T variant="subtitle1">You are ready to receive incoming calls</T>
      <InactivityDialog />
    </div>
  )
}

Idle.getLayout = getLayout;

// ...

export { 
  Idle 
};