import React from 'react';

import {
  makeStyles
} from '@material-ui/core/styles';

import {
  ReactComponent as AnnotationHotspotIcon
} from 'assets/annotation_type_hotspot.svg';

import {
  ReactComponent as AnnotationDrawIcon
} from 'assets/annotation_type_draw.svg';

import {
  ReactComponent as AnnotationArrowIcon
} from 'assets/annotation_type_arrow.svg';

import {
  ReactComponent as AnnotationCancelIcon
} from 'assets/annotation_cancel.svg';

// ...

const useStyles = makeStyles({
  wrapper: {
    height: 80,

    display: 'flex',
    justifyContent: 'space-between',
  },

  options: {
    height: '100%',
    display: 'flex'
  },

  button: {
    height: '100%',
    width: 'auto',
    cursor: 'pointer',
    marginRight: 15
  }
});

// ...

export function AnnotationSelect({ onSelect, onCancel }) {
  const styles = useStyles();

  return (
    <div className={styles.wrapper}>
      <div className={styles.options}>
        <AnnotationHotspotIcon 
          className={styles.button} 
          onClick={() => onSelect('HOTSPOT')}          
        />
        {/* Temporarily hidden free-hand draw icon per VBP-33 */}
        <AnnotationDrawIcon
          display="none"
          className={styles.button}
          onClick={() => onSelect('FREEFORM')}
        />
        <AnnotationArrowIcon
          className={styles.button}
          onClick={() => onSelect('ARROW')}
        />
      </div>
      <AnnotationCancelIcon 
        className={styles.button} 
        onClick={onCancel}
      />
    </div>
  )
}