import React, {
  useEffect,
  useState
} from 'react';

import {
  useLocation
} from 'react-router-dom';

import {
  Signin
} from './Signin';

import {
  PreSignup
} from './PreSignup';

import {
  Signup
} from './Signup';

import {
  PasswordReset
} from './PasswordReset';

import { 
  NewPassword 
} from './NewPassword';

import {
  PhoneFactor
} from './PhoneFactor';

import { 
  VerifyEmail 
} from './VerifyEmail';

import {
  useAuth
} from 'hooks';

// ...

export function Auth() {
  const [view, setView] = useState({
    mode: 'signin'
  });

  const location = useLocation();

  const {
    signOut,
    setMFAConfirmed
  } = useAuth();

    // Sign out and reset MFA status on load to handle case when user clicks back in browser from main view
    useEffect(() => {
      try {
        setMFAConfirmed(false);
        signOut();
      } catch {
        console.log("Error resetting user status");
      }
    }, []);
  
  //If we are coming in with a password reset code, redirect to the correct page
  useEffect(() => {
    if (location.search) {
      try {
        const mode = location.search.split("&")[0].split("=")[1];
        if (mode === "verifyEmail") {
          handleVerifyEmailRedirect();
        }
        else {
          handlePasswordResetRedirect();
        }
      } catch {
        console.log("Error parsing location.search");
      }
    }
  }, []);

  // Signin view actions.

  const handlePhoneFactor = async (phoneNumber) => {
    const mfaSessionInfo = { phoneNumber }; // used to be {verificationId, resolver, phoneInfoOptions}
    setView({
      mode: 'phoneFactor',
      data: mfaSessionInfo
    });
  }

  const handleRegister = async (credentials) => {

    setView({
      mode: 'presignup',
      data: credentials
    });
  };

  const handlePasswordReset = async (credentials) => {

    setView({
      mode: 'passwordReset',
      data: credentials
    });
  };

  const handlePasswordResetRedirect = async (credentials) => {

    setView({
      mode: 'passwordResetRedirect',
      data: credentials
    });
  };

  const handleVerifyEmailRedirect = async (credentials) => {

    setView({
      mode: 'verifyEmailRedirect',
      data: credentials
    });
  };

  // PreSignup view actions.
  const handlePreSignupSubmit = async (credentials) => {

    setView({
      mode: 'signup',
      data: credentials
    });
  };


  // Signup view actions.
  const handleCancel = () => setView({
    mode: 'signin'
  })

  switch (view.mode) {
    case 'signin':
      return (
        <Signin
          actions={{
            handleRegister,
            handlePasswordReset,
            handlePhoneFactor
          }}
        />
      )

    case 'presignup':
      return (
        <PreSignup
          credentials={view.data}
          actions={{
            handlePreSignupSubmit,
            handleCancel
          }}
        />
      )

    case 'signup':
      return (
        <Signup
          credentials={view.data}
          actions={{
            handleCancel,
            handlePhoneFactor
          }}
        />
      )

    case 'passwordReset':
      return (
        <PasswordReset
          credentials={view.data}
          actions={{
            handleCancel
          }}
        />
      )

    case 'passwordResetRedirect':
      return (
        <NewPassword
          credentials={view.data}
          actions={{
            handleCancel
          }}
        />
      )

    case 'verifyEmailRedirect':
      return (
        <VerifyEmail
          credentials={view.data}
          actions={{
            handleCancel
          }}
        />
      )

    case 'phoneFactor':
      return (
        <PhoneFactor
          mfa={view.data}
          actions={{
            handleCancel
          }}
        />
      )

    default:
      return null;
  }
}