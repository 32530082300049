import HotspotImage from 'assets/annotation_hotspot.svg';

import {
  loadImage,
  offsetFromMouseEvent,
  offsetFromTouchEvent
} from './util';

// ...

let hotspotImage;

async function getHotspot() {
  if (!hotspotImage) {
    hotspotImage = await loadImage(HotspotImage);
  }

  return hotspotImage;
}

// ...

export class Hotspot {
  position;
  creationTime;
  // disabling drawing hotspots because the glyphs look strange next to the live annotations
  draw = false;

  constructor(canvas, color, isFullscreen, callback, endCallback) {

    this.color = (color 
      ? `rgb(${Math.round(color.r * 255)}, ${Math.round(color.g * 255)}, ${Math.round(color.b * 255)})`
      : '#EECB4E'
    )

    this.canvas = canvas
    this.isFullscreen = isFullscreen
    this.callback = callback
    this.endCallback = endCallback
    
  }

  drawHotspot = async ({ offsetX, offsetY, elementWidth, elementHeight }) => {
    
    this.position = {
      x: offsetX,
      y: offsetY,      
    };

    this.creationTime = Date.now();

    // ...

    if (this.draw) {
      const hotspot = await getHotspot();

      const ctx = this.canvas.getContext('2d');
      ctx.clearRect(0, 0, elementWidth, elementHeight);

      // draw color
      ctx.fillStyle = this.color;
      ctx.fillRect(0, 0, elementWidth, elementHeight);

      // set composite mode
      ctx.globalCompositeOperation = "destination-in";
      ctx.drawImage(hotspot, elementWidth, elementHeight, hotspot.width, hotspot.height);

      ctx.globalCompositeOperation = "source-over";
    }
  }

  //erases the drawn hotspot
  clearHotspot = async () => {
    const ctx = this.canvas.getContext('2d');
    ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  onMouseDown = ({ nativeEvent }) => {  
    
    this.positionMetrics = offsetFromMouseEvent(nativeEvent)
    this.drawHotspot(this.positionMetrics);

    if (this.callback){
      this.callback(this.getAnnotationData());
    }

  }

  onTouchStart = event => {
    event.preventDefault();

    this.positionMetrics = offsetFromTouchEvent(event.nativeEvent)
    this.drawHotspot(this.positionMetrics);

    if(this.callback){
      this.callback(this.getAnnotationData());
    }
  }

  onMouseUp = ({ nativeEvent }) => {
    this.clearHotspot();
    this.endCallback(this.getAnnotationData());
  }

  onTouchEnd = event => {
    event.preventDefault();

    this.clearHotspot();
    this.endCallback(this.getAnnotationData());
  }
  // ...

  getEventHandlers() {
    return {
      onMouseDown: this.onMouseDown,
      onTouchStart: this.onTouchStart,
      onMouseUp: this.onMouseUp,
      onTouchEnd: this.onTouchEnd

    };
  }

  getAnnotationData = () => {
    
    return (this.isFullscreen
      ? this.getAnnotationData_fullscreen()
      : this.getAnnotationData_windowed()
    )

  }

  getAnnotationData_fullscreen = () => {

    const screenW = this.positionMetrics.elementWidth
    const screenH = this.positionMetrics.elementHeight

    const normX = (this.position.x - (screenW / 2)) / (screenW / 2);
    const normY = -(this.position.y - (screenH / 2)) / (screenH / 2);

    return {
      Position: {
        x: normX,
        y: normY
      },
      CreationTime: this.creationTime
    };

  }

  getAnnotationData_windowed = () => {

    if (!this.positionMetrics) {
      return null;
    }    

    // Quick references
    const posX = this.positionMetrics.offsetX;
    const posY = this.positionMetrics.offsetY;
    const elWidth = this.positionMetrics.elementWidth;
    const elHeight = this.positionMetrics.elementHeight;

    // Calculate the (x,y) position coordinate on a scale of [-1, 1].
    const normX = (posX - (elWidth / 2)) / (elWidth / 2);
    const normY = -(posY - (elHeight / 2)) / (elHeight / 2);

    return {
      Position: {
        x: normX,
        y: normY
      },
      CreationTime: this.creationTime
    };

  }
}