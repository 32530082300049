import { useLayoutEffect } from 'react'

export function useBodyScroll() {
  useLayoutEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow

    document.body.style.overflow = 'hidden, visible'

    return () => (document.body.style.overflow = originalStyle)
  }, [])
}