import React, {
  useCallback
} from 'react';

import {
  CircularProgress,
  Grid,
  Typography as T
} from '@material-ui/core';

import {
  InactivityDialog,
  Overlay,
  ProfileForm
} from 'components';

import {
  useAuth,
  useBodyScroll,
  useQuery
} from 'hooks';

import {
  useNavigate
} from 'react-router-dom';

import {
  fetchCollection
} from 'services/firebase';

// ...

export function Profile() {
  useBodyScroll();

  const {
    user,
    profile
  } = useAuth();

  const navigate = useNavigate();

  // **

  const formValues = useQuery(
    useCallback(() => {
      async function fetchCollections() {
        const [
          titles,
          departments,
          roles
        ] = await Promise.all([
          fetchCollection('titles'),
          fetchCollection('departments'),
          fetchCollection('roles')
        ]);

        return {
          titles,
          departments,
          roles
        };
      }

      return fetchCollections();
    }, [])
  );

  // ...

  if (!user || !profile || !formValues) {
    return (
      <Overlay
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress />
      </Overlay>
    )
  }

  // Set body overflow style to visible to allow scrolling. 
  // UseBodyScroll doesn't work due to ModalManager.js (invoked by Toolbar) 
  // setting this attribute to hidden after first call to this function
  document.body.style.overflowY = 'visible';
  document.body.style.overflowX = 'hidden';

  return (
    <Grid
      container
      spacing={1}
    >
      <Grid
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        item

        container
        justifyContent="center"
      >
        <T
          style={{
            paddingTop: 25,
            paddingBottom: 25
          }}
          variant="h5">
          Profile
        </T>
      </Grid>
      <Grid item>
        <ProfileForm
          data={{
            user,
            profile,
            formValues
          }}
          type='edit'
          actions={{ handleCancel: () => { navigate('/') } }}
        />
      </Grid>
      <InactivityDialog />
    </Grid>
  )
}