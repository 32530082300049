import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';

import App from './App';

// ...
const touchListener = (e) => {
  if (e.target instanceof HTMLCanvasElement) {
    e.preventDefault();
  }
}

document.body.addEventListener("touchstart", touchListener, { passive: false });
document.body.addEventListener("touchend", touchListener, { passive: false });
document.body.addEventListener("touchmove", touchListener, { passive: false });

// ...

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);