import React from 'react';

import { 
  Navigate,
  useLocation 
} from 'react-router-dom';

import { 
  useAuth
} from "../hooks";

// ...

export const RequireAuth = ({ redirectTo, children }) => {
  const {
    user,
    mfaConfirmed
  } = useAuth();

  const location = useLocation();

  // ...
  let isAuthenticated = user && mfaConfirmed === true;
  
  return isAuthenticated ? 
    children : 
    <Navigate to={redirectTo} state={{ from: location }} />;
};