import axios from 'axios';

import {
  getCloudFunctionUrl
} from 'firebaseConfig';

// ...

export async function getToken(params) {
  const result = await axios({
    url: getCloudFunctionUrl('generateToken'),
    method: 'POST',
    headers: {
      Authorization: process.env.REACT_APP_TOKEN_SECRET
    },
    params
  });

  return result.data;
}